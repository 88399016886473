import React,{useRef,useState} from "react";
import moment from "moment";
import { Table, Tag, Space, Button, Typography, Drawer,message } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import axios from "axios";
import useAxios from "./../../../CustomHooks/useAxios";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const Connected = ({ data,sttBegin }) => {
  console.log("[Connected]:data=", data);
  const [messageApi, contextHolder] = message.useMessage();
  let stt=(sttBegin)?sttBegin:1;

  const refAudio = useRef();
  const { axiosi } = useAxios();
  const [displayAudio, setDisplayAudio] = useState("none");
  const warning = (msg) => {
    messageApi.open({
      type: 'warning',
      content: msg,
    });
  };
  const handlePlayAudio = async (id, uniqueid) => {
    warning("loading record audio file ...");
    // refAudio.current.pause();
    setDisplayAudio("block");
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/fileLink?fileLink=${kq.data.fileLink}`
    refAudio.current.src = `${process.env.REACT_APP_DOMAIN}/totalReport_1800/fileLink?fileLink=${kq.data.fileLink}`;
    refAudio.current.play();
  };

  const handleDownloadAudio = async (id, uniqueid, pbxtel, tel, ext) => {
    warning("loading record audio file ...");
    // refAudio.current.pause();
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    //const linkfile=`https://gcall.com.vn:2943/calllogs/fileLink?fileLink=${kq.data.fileLink}`
    const linkfile = `${process.env.REACT_APP_DOMAIN}/totalReport_1800/fileLink?fileLink=${kq.data.fileLink}`;
    // window.open(linkfile);
    axios({
      url: linkfile, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${uniqueid}_${pbxtel}_${tel}_${ext}.wav`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  return (
    <>
    {contextHolder}
      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
            <th>connected</th> */}
            <th>No.</th>
            <th>
            Số máy gọi
            </th>
            <th>
            Số máy nhận
            </th>
            <th width="220px">Thời điểm gọi</th>
            
            <th width="220px">Pickup (s)</th>

            <th>Số phút thoại</th>
            <th>Agent</th>
            <th width="220px">Agent List</th>
            <th>File ghi âm</th>
            <th width="220px">Trạng thái</th>
            {/* <th>Các ghi chú</th> */}
            <th>ca</th>
            <th>Phím bấm </th>
            <th>Đồng ý</th>

            
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => {
              return <tr key={item.id }>
                <td>{stt++}</td>
               <td>{item.caller}</td> 
               <td>{item.callee}</td>
               <td>Begin: {moment(item.starttime).format(dateFormat)} <br/>
               End: {moment(item.EndTime).format(dateFormat)} <br/>
               </td>
               <td>Agent : {item.pickup_agent} <br/>
               Group : {item.pickup_group}
               </td>
               <td>{item.TalkTime}</td>
               <td>{item.AgentcodeName}</td>
               <td>{item.agentlist}</td>
               <td><Button
                      type="link"
                      onClick={() => handlePlayAudio(item.id, item.googledrive_id)}
                    >
                      <StepForwardOutlined />
                      Play
                    </Button> <br/>
                    <Button
                      type="link"
                      onClick={() =>
                        handleDownloadAudio(
                          item.id,
                          item.googledrive_id,
                          item.id,
                          item.id,
                          item.id
                        )
                      }
                    >
                      <DownloadOutlined />
                      Download
                    </Button>
                    
                    </td>
               <td>{item.status}</td>
               {/* <td>{item.note}</td> */}
               <td>{item.ca}</td>
               <td>{item.ivrkey}</td>
               <td>{item.ivrdongy}</td>

                </tr>;
            })}
        </tbody>
      </table>
      <div className="callLogAudio" style={{ display: displayAudio }}>
          <audio ref={refAudio} controls>
            <source src={""} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
          <Button type="link" onClick={() => setDisplayAudio("none")}>
            Close Audio
          </Button>
        </div>
    </>
  );
};

export default Connected;
