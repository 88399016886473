import { useRef, useState ,useEffect} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Table, Tag, Space, Button, Typography, Drawer } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import useAxios from "./../../../CustomHooks/useAxios";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const ShowTableSum_Total = ({ data, quantity_total,setRefressSearch, sttBegin,sapxep,setSapxep }) => {
  let stt=(sttBegin)?sttBegin:1;
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  // const [sapxep, setSapxep] = useState("DESC");
  const [total,setTotal]=useState(0);
  
  const displayPercent = (quantity) => {
    if (!quantity_total) return "";
    if (quantity_total === 0) return "";
    return ((quantity / quantity_total) * 100).toFixed(2);
  };
  
  return (
    <>
      <table className="table1">
          <thead>
            <tr>
              
              <th width="50px">No.</th>
              

              
              <th>IVR Key</th>
              <th>Quantity</th>
              
              <th>Percent_Total (%)</th>
              
              
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                
                <td>{stt++}</td>
                
                <td>
                  
                {item.ivrkey}

                </td>
                
                
                <td>
                  {item.quantity}
                </td>
                
                  <td>
                  {displayPercent(item.quantity)}
                  </td>
                
              </tr>
            ))}
          </tbody>
        </table>
    </>
  )
}

export default ShowTableSum_Total