import { useRef, useState ,useEffect} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Table, Tag, Space, Button, Typography, Drawer } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import useAxios from "./../../../CustomHooks/useAxios";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const ShowTable = ({ data, dep_quantity,setRefressSearch, sttBegin,sapxep,setSapxep }) => {
  let stt=(sttBegin)?sttBegin:1;
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  // const [sapxep, setSapxep] = useState("DESC");
  const [total,setTotal]=useState(0);
  useEffect(() => {
    console.log("data", data);
    let totalCall = 0;
    data.map((item) => {
      totalCall += item.quantity;
    });
    setTotal(totalCall);
  }, [data]);
  const displayPercent = (quantity) => {
    if (total === 0) return "";
    return ((quantity / total) * 100).toFixed(2);
  };
  const displayPercentDep = (quantity,depName) => {
    if (!dep_quantity ) return "";
    if (dep_quantity[depName] === 0) return "";
    return ((quantity / dep_quantity[depName]) * 100).toFixed(2);
  };
  return (
    <>
      <table className="table1">
          <thead>
            <tr>
              
              <th>No.</th>
              

              <th>Department</th>
              <th>IVR Key</th>
              <th>Quantity</th>
              <th>Percent_Dep (%)</th>
              
              
              
            </tr>
          </thead>
          <tbody>
            {data.map((item,index) => (
              <tr key={index}>
                
                <td>{stt++}</td>
                <td>{item.department_name}</td>
                <td>
                  
                {item.ivrkey}

                </td>
                
                
                <td>
                  {item.quantity}
                </td>
                <td>
                  {displayPercentDep(item.quantity,item.department_name)}
                  </td>
                  
                
              </tr>
            ))}
          </tbody>
        </table>
    </>
  )
}

export default ShowTable