import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import {
  message,
  Button,
  Row,
  Col,
  Divider,
  Typography,
  DatePicker,
  TimePicker,
  Select,
  Input,
  Space,
  Checkbox,
} from "antd";
import useAxios from "./../../../CustomHooks/useAxios";
import PageLink1 from "./../../PageLink/Page"; //PageLink';
import Connected from "./Connected";
import Missed from "./Missed";
import Abandoned from "./Abandoned";
import CallOut from "./CallOut";
import VoiceMail from "./VoiceMail";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";
const Old1800CallLogs = () => {
  const style = {
    //  background: 'rgb(178, 227, 156) none repeat scroll 0% 0%',
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
  };
  const { axiosi } = useAxios();
  const [messageApi, contextHolder] = message.useMessage();
  const [page, setPage] = useState(1);
  const [sFrom, setSFrom] = useState(
    moment(moment("2024-11-07 00:00").format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment("2024-11-08 00:00").format(dateFormat), dateFormat1)
    // moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );

  const [shiftName, setShiftName] = useState("");
  const [ivrKey, setIvrKey] = useState("");
  const [group, setGroup] = useState("");
  const [agent, setAgent] = useState("");
  const [allowPaging, setAllowPaging] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortTime, setSortTime] = useState("DESC");
  const [viewCallID, setViewCallID] = useState(false);

  const [tel, setTel] = useState("");
  const [agentGroup, setAgentGroup] = useState("");
  const [agents, setAgents] = useState([]);
  const [reportType, setReportType] = useState(0);
  const [searchRefresh, setSearchRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [sttBegin, setSttBegin] = useState(1);
  const warning = (msg) => {
    messageApi.open({
      type: "warning",
      content: msg,
    });
  };
  const doSearch = async (controller) => {
    if (!sFrom) {
      warning("Vui lòng chọn [Từ ngày] (YYYY-MM-DD HH:MM) !");
      return;
    }
    if (!sTo) {
      warning("Vui lòng chọn [Đến ngày] (YYYY-MM-DD HH:MM) !");
      return;
    }
    // console.log("sFrom:", sFrom.format(dateFormat1));
    // return;
    try {
      const sdata = {
        t: Date.now(),
        agentGroup,
        reportType,
        sFrom: sFrom.format(dateFormat1),
        sTo: sTo.format(dateFormat1),
        shiftName,
        ivrKey,
        agent,
        // allowPaging,
        pageSize: rowsPerPage,
        sortTime,
        viewCallID,
        tel,
        group,
        page: page,
        // pageSize: 10,
      };
      console.log(sdata);
      const kq = await axiosi.get("totalReport_1800", {
        params: sdata,
        signal: controller.signal, // Thêm signal để có thể hủy request
      });

      if (kq.status !== 204) {
        setData((pre) => kq.data);

        setSttBegin((kq.data.page - 1) * kq.data.pageSize + 1);
      } else {
        console.log("[No Content] kq.data=", kq.data);
        setData((pre) => []);
      }
    } catch (e) {
      console.log(e);
      setData((pre) => []);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    doSearch(controller);
    return () => {
      controller.abort(); // Cleanup: Hủy request khi component unmount
    };
  }, [searchRefresh, page,reportType]);
  useEffect(() => {
    // console.log("[Old1800CallLogs]:useEffect");
    const controller = new AbortController();
    const getAgents = async () => {
      try {
        const sdata = {
          t: Date.now(),
          agentGroup,
        };
        console.log(sdata);
        const kq = await axiosi.get("agents_1800/agentsFromGroup", {
          params: sdata,
          signal: controller.signal, // Thêm signal để có thể hủy request
        });
        console.log(kq.data?.rows);

        setAgents(kq.data?.rows || []);
        setAgent((pre) => "");
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.error("Error fetching agents:", error);
          setAgents([]);
        }
      }
    };
    getAgents();
    return () => {
      controller.abort(); // Cleanup: Hủy request khi component unmount
    };
  }, [agentGroup]);
  const handleChangeGroup = (value) => {
    setAgentGroup(value);
  };
  const handleSearch = () => {
    setPage(1);
    setSearchRefresh((pre) => !pre);
  };
  const rdData = (rType, dt) => {
    switch (rType) {
      case 0:
        return <Connected data={dt?.rows} sttBegin={sttBegin} />;
        break;
      case 1:
        return <Missed data={dt?.rows} sttBegin={sttBegin} />;
        break;
      case 2:
        return <Abandoned data={dt?.rows} sttBegin={sttBegin} />;
        break;
      case 3:
        return <CallOut data={dt?.rows} sttBegin={sttBegin} />;
        break;
      case 4:
        return <VoiceMail data={dt?.rows} sttBegin={sttBegin} />;
        break;
      default:
        return "";
        break;
    }
  };
  const handleExport = async () => {
    if (!sFrom) {
      warning("Vui lòng chọn [Từ ngày] (YYYY-MM-DD HH:MM) !");
      return;
    }
    if (!sTo) {
      warning("Vui lòng chọn [Đến ngày] (YYYY-MM-DD HH:MM) !");
      return;
    }
    try {
      const sdata = {
        t: Date.now(),
        agentGroup,
        reportType,
        sFrom: sFrom.format(dateFormat1),
        sTo: sTo.format(dateFormat1),
        shiftName,
        ivrKey,
        agent,
        // allowPaging,
        pageSize: rowsPerPage,
        sortTime,
        viewCallID,
        tel,
        group,
        page: page,
        // pageSize: 10,
      };

      console.log(sdata);
      const kq = await axiosi.get("totalReport_1800/export", {
        params: sdata,
        responseType: "blob",
      });
      console.log("[handleExport]:kq=", kq);
      console.log("[handleExport]:kq.data=", kq.data);
      // const kqtext=await kq.data.text();
      // console.log("[handleExportCallLog]:kq.data.text()=",kqtext)
      // console.log("[handleExportCallLog]:JSON.parse(kq.data.text())=",JSON.parse(kqtext))
      console.log("[handleExport]:typeof kq.data=", typeof kq.data);
      console.log("[handleExport]:kq.data.type=", kq.data.type);

      // var enc = new TextDecoder('utf-8')
      // var res =  JSON.parse(enc.decode (new Uint8Array( kq.data )));// convert to JSON object
      // console.log(res)

      // var b = new Blob([JSON.stringify({"test": "toast"})], {type : "application/json"})

      // console.log("[handleExportCallLog]:JSON.parse(kq.data)=",JSON.parse(kq.data))
      // console.log("[handleExportCallLog]:JSON.stringify(kq.data)=", JSON.stringify(kq.data))
      // const url = window.URL.createObjectURL(new Blob([kq.data],{type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exportCallLogs100.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();

      // const kqdata= await kq.data;
      // if (kq.status!==204) {
      // // setData(kq.data)
      // console.log("[getExportCallLogs]:",kqdata);
      // } else {
      //   console.log("[No Content] kq.data=",kq.data)
      // }
    } catch (error) {
      console.log("[handleExport]:", error);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };
  return (
    <>
      {contextHolder}
      <Typography.Title level={3}>Search :</Typography.Title>

      <div className="searchMenu">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Từ ngày (mm/dd/yyyy):</label>
              <DatePicker
                style={{ width: "100%" }}
                showTime
                maxDate={"2024-12-07 00:00"}
                minDate={"2024-01-01 00:00"}
                format={dateFormat1}
                value={sFrom}
                onChange={(value) => {
                  setSFrom(value);
                }}
              />
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Đến ngày (mm/dd/yyy):</label>
              <DatePicker
                style={{ width: "100%" }}
                showTime
                maxDate={"2024-12-07"}
                minDate={"2022-01-01"}
                format={dateFormat1}
                value={sTo}
                onChange={(value) => {
                  setSTo(value);
                }}
              />
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Shift Name :</label>
              <Select
                style={{ width: "100%" }}
                value={shiftName}
                onChange={(value) => setShiftName(value)}
              >
                <Select.Option value=""></Select.Option>
                <Select.Option value="Weekday_0_6H">Weekday_0_6H</Select.Option>
                <Select.Option value="Weekday_6_8H">Weekday_6_8H</Select.Option>
                <Select.Option value="Weekday_8_17H">
                  Weekday_8_17H
                </Select.Option>
                <Select.Option value="Weekday_17_24H">
                  Weekday_17_24H
                </Select.Option>

                <Select.Option value="Weekend_0_6H">Weekend_0_6H</Select.Option>
                <Select.Option value="Weekend_6_24H">
                  Weekend_6_24H
                </Select.Option>

                <Select.Option value="Holiday_0_6H">Holiday_0_6H</Select.Option>
                <Select.Option value="Holiday_6_24H">
                  Holiday_6_24H
                </Select.Option>
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>IVR Key:</label>
              <Select
                style={{ width: "100%" }}
                value={ivrKey}
                onChange={(value) => setIvrKey(value)}
              >
                <Select.Option selected></Select.Option>
                <Select.Option value="1">1 - Product Info</Select.Option>
                <Select.Option value="2">2 - Complain</Select.Option>
                <Select.Option value="3">3 - Nuti</Select.Option>
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Group :</label>
              <Select
                style={{ width: "100%" }}
                value={agentGroup}
                onChange={handleChangeGroup}
              >
                <Select.Option value=""></Select.Option>
                <Select.Option value="Nestle">Nestle</Select.Option>
                <Select.Option value="Outsource">Outsource</Select.Option>
                <Select.Option value="Extend">Extend</Select.Option>
                <Select.Option value="Extend2">Extend2</Select.Option>

                <Select.Option value="Bacsi">Bacsi</Select.Option>
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Agent :</label>
              <Select
                style={{ width: "100%" }}
                value={agent}
                onChange={(value) => setAgent(value)}
              >
                <Select.Option value={""}></Select.Option>
                {agents &&
                  agents.map((agent) => {
                    return (
                      <Select.Option value={agent.Code}>
                        {agent.CodeName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {/* <Col className="gutter-row" span={4}>
            <div className="col">
              <label>&nbsp;</label>
              Cho phép phân trang: <Checkbox value={allowPaging} onChange={(e)=>setAllowPaging(e.target.value)}  ></Checkbox>
            </div>
          </Col> */}
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Số dòng / trang:</label>
              <input
                type="number"
                min={1}
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
              />
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Sắp xếp theo thời gian:</label>
              <Select style={{ width: "100%" }}>
                <Select.Option value={"DESC"}>Tăng dần</Select.Option>
                <Select.Option value={"ASC"}>Giảm dần</Select.Option>
              </Select>
            </div>
          </Col>
          {/* <Col className="gutter-row" span={4}>
            <div className="col">
              <label>&nbsp;</label>
              <span style={{display:"flex",flexDirection:"row"}}><span style={{minWidth:"5rem"}}>Xem CallID:</span><Checkbox value={viewCallID} onChange={(e)=>setViewCallID(e.target.value)}></Checkbox>
              </span>
            </div>
          </Col> */}
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Tel:</label>
              <Input value={tel} onChange={(e) => setTel(e.target.value)} />
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>Báo cáo:</label>
              <Select
                style={{ width: "100%" }}
                value={reportType}
                onChange={(value) =>{ 
                  
                  setReportType(value);
                }}
              >
                <Select.Option value={0}>Cuộc gọi đã xử lý</Select.Option>
                <Select.Option value={1}>Cuộc gọi nhỡ</Select.Option>
                <Select.Option value={2}>Cuộc gọi chưa xử lý</Select.Option>
                <Select.Option value={3}>Cuộc gọi ra</Select.Option>
                <Select.Option value={4}>Voice Mail</Select.Option>
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>&nbsp;</label>
              <Button onClick={handleSearch}>Search</Button>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div className="col">
              <label>&nbsp;</label>
              <Button onClick={handleExport}>Export</Button>
            </div>
          </Col>
        </Row>
        {/* <div className="row">
          
          
        </div> */}
      </div>

      <div className="searchResult">{rdData(reportType, data)}</div>
      <Row>
        <Col span={24}>
          {data?.pages > 1 ? (
            <PageLink1
              pageSize={data.pageSize}
              searchpage={"old_1800_CallLog"}
              pages={data.pages}
              numrows={data.numrows}
              page={page}
              setPage={setPage}
            />
          ) : (
            <h1></h1>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Old1800CallLogs;
