import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Drawer, Button, Typography, Input, Select, DatePicker } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import moment from "moment";
import useAxios from "./../../../CustomHooks/useAxios";
import ShowTable from "./ShowTable";
import PageLink from "./../../PageLink/PageLink";
import Add from "./Add";
import DebounceSelect from "./../../DebounceSelect";
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
export default function Tickets() {
  let { page } = useParams();
  let navigate = useNavigate();
  const { axiosi } = useAxios();

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [data, setData] = useState();

  const [companyid, setCompanyid] = React.useState();
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [ccomname, setCcomname] = useState("");
  const [tnstatus, setTNstatus] = useState("");
  const [sFrom, setSFrom] = useState(moment(moment(), dateFormat));
  const [sTo, setSTo] = useState(moment(moment().add(1, "days"), dateFormat));
  const [refressSearch, setRefressSearch] = useState(true);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  useEffect(() => {
    doSearch();
  }, [refressSearch, page]);
  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
    // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
    //   .then((body) =>
    //     body?.data?.rows.map((user) => ({
    //      label: user.company_name,
    //       value: user.id,
    //     })),
    //   );
  }
  const handleAdd = () => {
    console.log("[handleAdd]:");
    setVisibleAdd(true);
  };
  const onCloseAdd = () => {
    setVisibleAdd(false);
  };
  const handleSearch = async () => {
    console.log("[handleSearch]");
    setRefressSearch((pre) => !pre);
  };
  const doSearch = async () => {
    console.log("[doSearch]");
    // navigate('/ivrplanmain');
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const kq = await axiosi.get("ticketnote", {
        params: {
          page,
          sFrom: timeFrom,
          sTo: timeTo,
          tel,
          ccomname,
          name,
          status: tnstatus,
          company_id: companyid?.value,
        },
      });
      console.log("[handleSearch]:kq.data=", kq.data);
      setData(kq.data);
    } catch (err) {
      console.log("ERROR");
    }
  };
  const handleExport = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat);
        console.log("sFrom=", sFrom.format(dateFormat));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat);
        console.log("sTo:=", sTo.format(dateFormat));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      const kq = await axiosi.get("ticketnote/export", {
        params: {
          page,
          sFrom: timeFrom,
          sTo: timeTo,
          tel,
          ccomname,
          name,
          status: tnstatus,
          company_id: companyid?.value,
        },
        responseType: "blob",
      });
      console.log("[handleExport]:kq=", kq);
      console.log("[handleExport]:kq.data=", kq.data);
      console.log("[handleExport]:typeof kq.data=", typeof kq.data);
      console.log("[handleExport]:kq.data.type=", kq.data.type);

      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      
      link.setAttribute("download", `exportTicket.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.log("ERROR");
    }
  };

  function onChangeSFrom(date, dateString) {
    // console.log(date, dateString);
    setSFrom(dateString);
  }

  function onChangeSTo(date, dateString) {
    // console.log(date, dateString);
    setSTo(dateString);
  }
  return (
    <>
      {/* <Upload/>
  <UploadAntd/> */}
      <Drawer
        title="Add  Menu Item"
        placement="right"
        onClose={onCloseAdd}
        visible={visibleAdd}
      >
        <Add
          setRefressSearch={setRefressSearch}
          setVisibleAdd={setVisibleAdd}
        />
      </Drawer>
      <section className="search">
        <div className="container">
          <div className="row">
            <Typography.Title level={3}>
              Search :
              {/* <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
            </Typography.Title>
          </div>

          <div className="row">
            <div className={hideItem}>
              <label>Company :</label>
              <DebounceSelect
                showSearch={true}
                value={companyid}
                placeholder="Select a company !"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setCompanyid(newValue);
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="col">
              <label>From :</label>
              <DatePicker
                onChange={onChangeSFrom}
                defaultValue={sFrom}
                format={dateFormat}
              />
            </div>
            <div className="col">
              <label>To :</label>
              <DatePicker
                onChange={onChangeSTo}
                defaultValue={sTo}
                format={dateFormat}
              />
            </div>

            <div className="col">
              <label>Tel :</label>
              <Input onChange={({ target: { value } }) => setTel(value)} />
            </div>
            <div className="col">
              <label>Name :</label>
              <Input onChange={({ target: { value } }) => setName(value)} />
            </div>
            <div className="col">
              <label>Company :</label>
              <Input onChange={({ target: { value } }) => setCcomname(value)} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Ticket Status :</label>
              <Select
                placeholder=" "
                value={tnstatus}
                onChange={(value) => setTNstatus(value)}
                className="form-input form-select"
                defaultValue=""
              >
                <Option value=""></Option>
                <Option value="New">New</Option>
                <Option value="Processing">Processing</Option>
                <Option value="Successful">Successful</Option>
                <Option value="Failed">Failed</Option>
              </Select>
            </div>
            <div className="col">
              <label>Search:</label>
              <Button onClick={handleSearch}>Search</Button>
            </div>
            <div className="col">
              <label>Export:</label>
              <Button onClick={handleExport}>Export</Button>
            </div>
          </div>
        </div>
      </section>
      <section className="searchResult">
        <div className="row">
          <Typography.Title level={3}>Result</Typography.Title>
        </div>
        <div className="row">
          {data?.rows && (
            <ShowTable data={data.rows} setRefressSearch={setRefressSearch} />
          )}
        </div>
        <div className="row">
          {data?.pages > 1 ? (
            <PageLink
              pageSize={data.pageSize}
              searchpage={"tickets"}
              pages={data.pages}
              numrows={data.numrows}
              page={data.page}
            />
          ) : (
            <h1>chi co' 1 page</h1>
          )}
        </div>
      </section>
    </>
  );
}
