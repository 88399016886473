import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;

export default function Edit({item,setRefressSearch,setVisibleEdit,setSelectedItem}) {
  console.log("[Edit]:item=",item);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const {axiosi}=useAxios();
  const [form] = Form.useForm();
  const [file, setFile] = useState();
  const [iaction,setIaction]=useState(item.iaction);
    const [companyid, setCompanyid] = React.useState({value:item.company_id,label:item.company_name});

    const [department_id,setDepartment_id]=useState(item?.department_id);
    const [department_idData,setDepartment_idData]=useState(null);
    const [departOptions,setDepartOptions]=useState([])

    const [exten,setExten]=useState({value:"",label:""});
    const [itoSelectMode,setItoSelectMode]=useState('');
    const [forwardTo,setForwardTo]=useState(item.ito);

    const [telsoutData,setTelsoutData]=useState(null);
    const [telsoutDataLoading,setTelsoutDataLoading]=useState(false);

    const [telout,setTelout]=useState(item.forwardtelid);
    const [txtToSpeech,setTxtToSpeech]=useState(item.txtToSpeech);
    const [nextMainDialPlanData,setNextMainDialPlanData]=useState(null);
    const [nextMainDialPlainSelectedid,setNextMainDialPlainSelectedid]=useState(item.nextmaindialplanid);

    const [ivrVisible,setIVRVisible]=useState({
      ito:false,
      timeout:false,
      maxdigits:false,
      attempts:false,
      forwardtelid:false,
      file:false, 
      tts:false,      
    })

    // useEffect(()=>{
      
    //   setCompanyid({value:item.company_id,label:item.company_name})
    //   setIaction(item.iaction)
    //   setForwardTo(item.ito);
    //   setTelout(item.forwardtelid);
    //   setNextMainDialPlainSelectedid(item.nextmaindialplanid);
    // },[item])

    
    useEffect(()=>{
      const getDepartment=async()=>{
        const kq= await axiosi.get('department',{params:{company_id:companyid?.value,pageSize:100}});
        console.log("[getDepartment]:kq=",kq);
        
        setDepartment_idData(kq.data);
        setDepartment_id(item?.department_id);
        kq?.data?.rows.forEach(dep => {
          setDepartOptions((pre)=>[...pre,{
            label:dep.name ,
            value: dep.id,

          }])
        });
      }
      getDepartment();
    },[companyid?.value])

    useEffect(()=>{
      const getNextMainDialplan=async()=>{
        try {
          if (companyid){
        const kq=await axiosi.get('ivrplanmain',{params:{company_id:companyid.value,pageSize:1000}});
        console.log("[useEffect-getNextMainDialplan]:kq=",kq)
        setNextMainDialPlanData(kq.data.rows);
          } else {
            setNextMainDialPlanData(null);
          }
        }
        catch (err){
          console.log("[useEffect-getNextMainDialplan]:err=",err);
        }

      }
      getNextMainDialplan();
    },[companyid])

    useEffect(()=>{
      switch(iaction){
        case "PlayTTS":
          setItoSelectMode('');
          setExten([]);
          setIVRVisible({
            ito:false,
            itoForward:false,
            timeout:false,
            maxdigits:false,
            attempts:false,
            forwardtelid:false,
            file:false, 
            tts:true,      
          })
          break;
        case "Play":
          setItoSelectMode('');
          setExten([]);
          setIVRVisible({
            ito:false,
            itoForward:false,
            timeout:false,
            maxdigits:false,
            attempts:false,
            forwardtelid:false,
            file:true, 
            tts:true,      
          })
          break;
          case "Read":
            setItoSelectMode('');
            setExten([]);
            setIVRVisible({
              ito:false,
              itoForward:false,
              timeout:true,
              maxdigits:true,
              attempts:true,
              forwardtelid:false,
              file:true, 
              tts:true,      
            })
            break;
            case "ReadTTSKey":
              setItoSelectMode('');
              setExten([]);
              setIVRVisible({
                ito:false,
                itoForward:false,
                timeout:true,
                maxdigits:true,
                attempts:true,
                forwardtelid:false,
                file:false, 
                tts:true,      
              })
              break;
              case "ReadTTSVoice":
                setItoSelectMode('');
                setExten([]);
                setIVRVisible({
                  ito:false,
                  itoForward:false,
                  timeout:true,
                  maxdigits:false,
                  attempts:true,
                  forwardtelid:false,
                  file:false, 
                  tts:true,      
                })
                break;
          case "Dial":
            setItoSelectMode('');
            setExten([]);
            setIVRVisible({
              ito:true,
              itoForward:false,
              timeout:true,
              maxdigits:false,
              attempts:false,
              forwardtelid:false,
              file:false, 
              tts:false,      
            })
            break;
            case "Forward":
            setItoSelectMode('');
            setExten([]);
            setIVRVisible({
              ito:false,
              itoForward:true,
              timeout:true,
              maxdigits:false,
              attempts:false,
              forwardtelid:true,
              file:false,  
              tts:false,     
            })
            break;
            case "Queue":
              setItoSelectMode('multiple');
              setExten([]);
              setIVRVisible({
                ito:true,
                itoForward:false,
                timeout:true,
                maxdigits:false,
                attempts:false,
                forwardtelid:false,
                file:false, 
                tts:false,      
              })
              break;
            
            case "VM":
              setItoSelectMode('');
              setExten([]);
              setIVRVisible({
                ito:false,
                timeout:true,
                maxdigits:false,
                attempts:false,
                forwardtelid:false,
                file:false, 
                tts:false,      
              })
              break;
        default: 
          setItoSelectMode('');
          setExten('');
          setIVRVisible({
            ito:false,
            itoForward:false,
            timeout:false,
            maxdigits:false,
            attempts:false,
            forwardtelid:false,
            file:false,    
            tts:false,   
          })
          break;
          break;
      }
    },[iaction])

    useEffect(()=>{
      const getato=async() =>{
        let kq;
      if (item.iaction==="Dial"){
        kq=await axiosi.get('agents/searchExten',{params:{company_id:item.company_id,endpoint_id:item.ito,pageSize:100}} );
        console.log("[useEffect]:agents/searchExten = ",kq?.data?.rows[0]);
        setExten(kq?.data?.rows[0]);
        // setExten([{value:item.ito,label:item.ito}]);
      // setExten({value:item.ito,label:item.ito});
      } else if(item.iaction==="Queue") {
        kq=await axiosi.get('queue_members/getQueueMembers',{params:{queue_name:`${item.ito}${item.id}`,pageSize:100}} );
        console.log("[useEffect]:queue_members/getQueueMembers = ",kq?.data?.rows);
        setExten(kq?.data?.rows);
        //setExten([{value:item.ito,label:item.ito},{value:"6308_7d9305e51",label:"6308KKK"}]);
      }
      }
      getato();
      
    },[item.iaction,item.ito,item.id])
   async function fetchUserList(company_name) {        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

    async function fetchExtenList(exten) {
        
      console.log('[fetchExtenList]:exten=', exten);
      const body=await axiosi.get('agents/searchExten',{params:{company_id:companyid.value,exten,pageSize:100}} );
      if (body?.data?.rows) {
          return body?.data?.rows.map((user) => ({
              label: user.name,
               value: user.endpoint_id,
             }));
      }
      else {
          const a=[{label:"---no data---",value:""}]
          return a.map(i=> (i))
      }
     
  }
    
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        console.log("[onFinishAdd]:companyid=",companyid);
        console.log("[onFinishAdd]:telsoutData=",telsoutData);
        console.log("[onFinishAdd]:telout=",telout);
        const teloutSelected=telsoutData?.rows?.find((item)=>item.id===telout)
        console.log("[onFinishAdd]:teloutSelected=",teloutSelected);
        console.log("[onFinishAdd]:file=",file);
        // const newAgent={...values,
        //   // name:values.name,
        //   // username,
        //   password,
        //   authorization_group_id,
        //   endpoint_id,
        //   company_id,
        //   telout_num,
        //   telout_peer,
        //   max_contacts,
        //   ep_username,
        //   ep_password,
        //   note,
        //   tel,
        //   email,
        //   webrtc,
        // }
        // return;
        let ito="";
        switch(iaction){
          case "Dial":
            ito=exten.value;
            break;
          case "Queue":
            ito=`Group_${companyid.value}_`;
            break;
          case "Forward":
            ito=forwardTo;
            break;
          default:
            break;
        }
        console.log("[onFinishAdd]:exten=",exten);
        console.log("[onFinishAdd]:ito=",ito);
        let adddata={...values,id:item.id,
          company_id:companyid.value ,
          iaction ,
          ito:ito ,
          exten,
          txtToSpeech,
          lang ,
         department_id };
        if (nextMainDialPlainSelectedid!==''){
          adddata={...adddata,nextmaindialplanid: nextMainDialPlainSelectedid };
        }
        if (iaction==="Forward") {
          adddata={...adddata,forwardtelid:telout};
        }
        console.log("[onFinishAdd]:adddata=",adddata);

        const data = new FormData();
        // data.append("name", "123");    
        // data.append("a", "name la a");
        data.append("adddata",JSON.stringify(adddata));
        
        // console.log("[handleUpload]:data=",data);
        data.append("adddata2","data2");
        if (iaction==="Play" || iaction==="Read") {
          data.append("file", file);
        } else {
          data.append("file", file);
        }
        // return;
        try {
            const kq=await axiosi.put(`ivrplanmain/${item.id}`,data);
            console.log("[onFinishAdd]:kq=",kq) ;
            // form.resetFields();
            setSelectedItem(null);
            setVisibleEdit(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
    }
    useEffect(()=>{
      const getTeloutData = async ()=>{
        const kq= await axiosi.get('tels',{params:{companyid:item.company_id,pageSize:100}});
          // console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout(item.forwardtelid);
      }
      getTeloutData();
          
    },[])
    const handleOnChangeIto=async(value)=>{
      console.log("[handleOnChangeIto]:value=",value);
      setExten(value);
    }
    const handleOnChangeTelsout=async(value)=>{
      setTelout(value);
      console.log(value);
    }
    const [lang,setLang]=useState(()=>item.lang?item.lang:"vi")
    const handleOnChangeLang=(value)=>{
      setLang(value);
    }
    const handleOnChangeIaction=(value)=>{
      // setFile(null);
      // form.setFieldsValue({ note: 'Hi there!' });
      setIaction(value);
      // switch(value){
      //   case "Play":
      //     setItoSelectMode('');
      //     setExten([]);
      //     setIVRVisible({
      //       ito:false,
      //       timeout:false,
      //       maxdigits:false,
      //       attempts:false,
      //       forwardtelid:false,
      //       file:true,      
      //     })
      //     break;
      //     case "Read":
      //       setItoSelectMode('');
      //       setExten([]);
      //       setIVRVisible({
      //         ito:false,
      //         timeout:true,
      //         maxdigits:true,
      //         attempts:true,
      //         forwardtelid:false,
      //         file:true,      
      //       })
      //       break;
      //     case "Dial":
      //       setItoSelectMode('');
      //       setExten([]);
      //       setIVRVisible({
      //         ito:true,
      //         timeout:true,
      //         maxdigits:false,
      //         attempts:false,
      //         forwardtelid:false,
      //         file:false,      
      //       })
      //       break;
      //       case "Forward":
      //       setItoSelectMode('');
      //       setExten([]);
      //       setIVRVisible({
      //         ito:true,
      //         timeout:true,
      //         maxdigits:false,
      //         attempts:false,
      //         forwardtelid:true,
      //         file:false,      
      //       })
      //       break;
      //       case "Queue":
      //         setItoSelectMode('multiple');
      //         setExten([]);
      //         setIVRVisible({
      //           ito:true,
      //           timeout:true,
      //           maxdigits:false,
      //           attempts:false,
      //           forwardtelid:false,
      //           file:false,      
      //         })
      //         break;
      //   default: 
      //     setItoSelectMode('');
      //     setExten('');
      //     setIVRVisible({
      //       ito:false,
      //       timeout:false,
      //       maxdigits:false,
      //       attempts:false,
      //       forwardtelid:false,
      //       file:false,      
      //     })
      //     break;
      //     break;
      // }
    }

    const handleOnChangeForwardTo=(value)=>{
      console.log("[handleOnChangeForwardTo]:value.target.value=",value.target.value)
      setForwardTo(value.target.value);
    }

    const handleOnChangeNextMainDialPlan=(value)=>{
      setNextMainDialPlainSelectedid(value);
      console.log("[handleOnChangeNextMainDialPlan]:value=",value)
    }
    function onSearch(val) {
      console.log('search:', val);
    }
    // if (1)return (<h1>hello</h1>)
    async function handleTextToSpeeech(){
      console.log("[handleTextToSpeeech]:txtToSpeech=",txtToSpeech);
      try {
        const kq=await axiosi.post("ivrplanmain/vttts",{txtToSpeech});
        console.log("[handleTextToSpeeech]:kq=",kq) 
        
      } catch (error){
        console.log("[handleTextToSpeeech]:Error=",error) 
      }
    }
  return (<>
  {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
        <Form form={form}
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={item}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      <Form.Item className={"hideItem"}
        label="ordnum :"
        name="ordnum"
        rules={[{ required: true, message: 'Please input an ordnum!' }]}
      >
        <Input  />        
      </Form.Item>

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: (hideItem!=="hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>

      <Form.Item
        label="Name :"
        name="name"
        rules={[{ required: true, message: 'Please input a Main IVR Name!' }]}
      >
        <Input  />        
      </Form.Item>
      
      <Form.Item
        label="Department"
      >
        {department_idData?.rows && <>
            <Select style={{ width: '100%' }} 
                    
                    onChange={(value)=>setDepartment_id(value)}                   
                    // value={department_id}  
                    defaultValue={item.department_id}                  
            >
                        <Option value=""></Option>
                        { department_idData?.rows && department_idData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}         
        </Form.Item>
      

      <Form.Item
        label="iaction :"
        // name="iaction"
        rules={[{ required: true, message: 'Please input an iaction!' }]}
      >
        <Select style={{ width: '100%' }}  value={iaction} onChange={handleOnChangeIaction}>
          <Option  value={""}></Option>
          <Option  value={"Play"}>Playback</Option>
          <Option  value={"PlayTTS"}>Playback TTS for Survey,AutoCall</Option>
          {/* <Option  value={"Playback0"}>Playback with Early Ring</Option> */}
          <Option  value={"Read"}>Read Menu for IVR Key</Option>
          <Option  value={"ReadTTSKey"}>ReadTTS MenuKey  for Survey,AutoCall</Option>
          <Option  value={"ReadTTSVoice"}>ReadTTS MenuVoice for Survey,AutoCall</Option>

          <Option  value={"Dial"}>Dial to Exten</Option> 
          <Option  value={"Forward"}>Forward to PSTN numeber</Option>        
          <Option  value={"Queue"}>To Agent Group</Option>
          <Option  value={"VM"}>To Voice Mail</Option>
        </Select> 
      </Form.Item>
      <Form.Item
        label="file :"
        name1="file"
        rules={[{ required: false, message: 'Please input an filename!' }]}
        hidden={!ivrVisible.file}
      >
        <Input type="file"  allowClear  onChange={(e) => setFile(e.target.files[0])} />        
      </Form.Item>
      <Form.Item
        label="TextToSpeech :"
        // name1="file"
        rules={[{ required: false, message: 'Please input an filename!' }]}
        hidden={!ivrVisible.tts}
      >
        <Input.TextArea value={txtToSpeech}   onChange={(e) => setTxtToSpeech(e.target.value) } />   
        {/* <Button onClick={handleTextToSpeeech} >ConvertTextToSpeech</Button>      */}
      </Form.Item>
      <Form.Item
        label="TTS Voice :"
        // name1="file"
        rules={[{ required: false, message: 'Please input an filename!' }]}
        hidden={!ivrVisible.tts}
      >
        <Select style={{ width: '100%' }}  value={lang} onChange={handleOnChangeLang} >
            <Option value='vi'>Việt Nam 1</Option>
            <Option value='hcm-diemmy'>Việt Nam 2</Option>            
            <Option value='en'>English</Option>
        </Select>   
        
      </Form.Item>

      {/* <Form.Item
        label="iactiond :"
        name="iactiond"
        rules={[{ required: true, message: 'Please input an iactiond!' }]}
      >
        <Input  />        
      </Form.Item> */}

      <Form.Item
        label="ito :"
        // name="ito"
        rules={[{ required: false, message: 'Please input an ito!' }]}
        // {ivrVisible.ito && (hidden)}
        hidden={!ivrVisible.ito}
      >       
        
        <DebounceSelect selectMode={itoSelectMode}
          showSearch={true}
          value={exten}
          placeholder="Select users"
          fetchOptions={fetchExtenList}
          onChange={handleOnChangeIto}          
          style={{  width: '100%', }}
        />
    
      </Form.Item>

      {/* <Form.Item
        label="itoGroup:"
        // name="ito"
        rules={[{ required: false, message: 'Please input an ito!' }]}
        // {ivrVisible.ito && (hidden)}
        hidden={!ivrVisible.ito}
      >
        <DebounceSelect selectMode={itoSelectMode}
          showSearch={true}
          value={exten}
          placeholder="Select users"
          fetchOptions={fetchExtenList}
          onChange={handleOnChangeIto}          
          style={{  width: '100%', }}
        />
      
      </Form.Item> */}
      <Form.Item
        label="itoForward :"
        // name="ito"
        rules={[{ required: false, message: 'Please input an ito!' }]}
        // {ivrVisible.ito && (hidden)}
        hidden={!ivrVisible.itoForward}
      >
        <Input value={forwardTo} onChange={handleOnChangeForwardTo}/>
     
      </Form.Item>

      

      <Form.Item
        label="maxdigits :"
        name="maxdigits"
        rules={[{ required: false, message: 'Please input an maxdigits!' }]}
        // visible={ivrVisible.maxdigits}
        hidden={!ivrVisible.maxdigits}
      >
        <Input  />        
      </Form.Item>

      <Form.Item
        label="attempts :"
        name="attempts"
        rules={[{ required: false, message: 'Please input an attempts!' }]}
        hidden={!ivrVisible.attempts}
      >
        <Input  />        
      </Form.Item>

      

      <Form.Item
        label="forwardtelid"
        name1="forwardtelid"
        rules={[{ required: false, message: 'Please input an forwardtelid!' }]}
        hidden={!ivrVisible.forwardtelid}
      >
        {telsoutData?.rows && <>
            <Select style={{ width: '100%' }} 
                    onChange={handleOnChangeTelsout}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={telout}
                    
            >
                        <Option value=""></Option>
                        { telsoutData?.rows && telsoutData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.telout}</Option>
                       ))}                       
            </Select>
        
      </>}
         {/* {!telsoutData?.rows && <Input/>} */}
        </Form.Item>

        

      {/* <Form.Item
        label="filename :"
        name="filename"
        rules={[{ required: false, message: 'Please input an filename!' }]}
      >
        <Input  />        
      </Form.Item> */}

      

      {/* <Form.Item
        label="queuename :"
        name="queuename"
        rules={[{ required: false, message: 'Please input an queuename!' }]}
      >
        <Input  />        
      </Form.Item> */}
{/* 
      <Form.Item label="Author group" name="authorization_group_id">
        <Select style={{ width: '100%' }} 
                    onChange={(value)=>setAuthorization_group_id(value)}
                    loading={authorization_groupsLoading}
                    
                    >
                        <Option value=""></Option>
                       { authorization_groups?.rows && authorization_groups.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
                    </Select>
        </Form.Item> */}
      
    
        

      <Form.Item
        label="timeout :"
        name="timeout"
        rules={[{ required: false, message: 'Please input an timeout!' }]}
        hidden={!ivrVisible.timeout}
      >
        <Input  />        
      </Form.Item>

      <Form.Item
        label="moh class :"
        name="mohid"
        rules={[{ required: false, message: 'Please input moh Class ID!' }]}
        // hidden={!ivrVisible.timeout}
      >
        <Input  />        
      </Form.Item>

      <Form.Item
        label="checknqueuefree :"
        name="checknqueuefree"
        rules={[{ required: false, message: 'checknqueuefree!' }]}
        // hidden={!ivrVisible.timeout}
      >
        <Input  />        
      </Form.Item>
      

      <Form.Item
        label="nextmaindialplanid :"
       // name="nextmaindialplanid"
        rules={[{ required: false, message: 'Please input an nextmaindialplanid!' }]}
      >
        {nextMainDialPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeNextMainDialPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={nextMainDialPlainSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { nextMainDialPlanData && nextMainDialPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}  
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
