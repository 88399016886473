import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const GetIP = () => {
  const [curIP, setCurIP] = useState('');
  const [prevIP, setPrevIP] = useState('');

  const fetchIP = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ip`);
      const newIP = response.data.ip;
      // console.log({newIP});
      setCurIP((currentIP) => {
        if (currentIP !== newIP) {
          // console.log({newIP, curIP, prevIP});
          setPrevIP(currentIP);
        }
        return newIP;
      });
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  }, []);

  useEffect(() => {
    fetchIP();
    const timer = setInterval(fetchIP, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [fetchIP]);

  return (
    <>
      <div>Current IP: {curIP}</div>
      {prevIP && <div>Previous IP: {prevIP}</div>}
    </>
  );
};

export default GetIP;